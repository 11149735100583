// Grey Palette
export const grey = {
    50: "#fafafa",
    100: "#f4f4f5",
    200: "#e4e4e7",
    300: "#d4d4d8",
    400: "#a1a1aa",
    450: "#909099",
    500: "#4f5352",
    600: "#363a39",
    700: "#272b2a",
    800: "#1b1f1e",
    900: "#121615",
};
